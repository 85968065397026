import React, { Component } from 'react'
import vrGuideImg1 from '../images/vrGuide1.png'
import vrGuideImg2 from '../images/vrGuide2.png'
import vrGuideImg3 from '../images/vrGuide3.png'
import vrGuideImg4 from '../images/vrGuide4.png'
import vrGuideImg5 from '../images/vrGuide5.png'
import vrGuideImg6 from '../images/vrGuide6.png'
import vrGuideImg7 from '../images/vrGuide7.png'
import vrGuideImg8 from '../images/vrGuide8.png'
import vrGuideImg9 from '../images/vrGuide9.png'
import vrGuideImg10 from '../images/vrGuide10.png'
import vrGuideImg11 from '../images/vrGuide11.png'
import vrGuideImg12 from '../images/vrGuide12.png'
import vrGuideImg13 from '../images/vrGuide13.png'
import vrGuideImg13_1 from '../images/vrGuide13_1.jpg'
import vrGuideImg14 from '../images/vrGuide14.png'
import vrGuideImg15 from '../images/vrGuide15.png'
import vrGuideImg16 from '../images/vrGuide16.png'
import arGuideImg1 from '../images/arGuide1.png'
import arGuideImg2 from '../images/arGuide2.png'
import arGuideImg3 from '../images/arGuide3.png'
import arGuideImg4 from '../images/arGuide4.png'
import arGuideImg5 from '../images/arGuide5.png'
import arGuideImg6 from '../images/arGuide6.png'
import arGuideImg7 from '../images/arGuide7.png'
import arGuideImg8 from '../images/arGuide8.png'

import arGuideImgApp from '../images/vrGuideApp.png'


class Manual extends Component {
    render() {
        let { instituteUser } = this.props
        return (
            <div className="deviceMenualGuideFrame">
                {/* <h5>추후 업로드 예정입니다.</h5>
                <div>관련 사항은 1599-1432로 문의 바랍니다.</div> */}
                <h5>VR 이용 기기별 설치방법</h5>
                <ul className="vrMenualGuide">
                    {
                !instituteUser &&
                <>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg13_1} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>오큘러스 고 이용시 </span>
                                <span>1. 모바일에 오큘러스 앱<img src={arGuideImgApp} alt="앱 이미지"/>을 설치 한 후 자신의 오큘러스 계정(또는 Facebook 계정)으로 접속합니다.</span>
                                <span>2-1. 앱 하단 [설정]을 선택하거나(구버전)</span>
                                <span>2-2. 좌측 상단 프로필 클릭 - [계정 설정]을 선택(신버전) 후</span>
                                <span>3. [코드 사용] 혹은 [매장 코드 사용]을 선택합니다.</span>
                            </li> 
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg14} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span> </span>
                                <span>4. 발급받은  다운로드 코드(25자)를 입력하세요.</span>
                                <span>5. 올바른 코드를 입력하면 자신의 계정에 앱등록이 완료됩니다.</span><br />
                                <span className="deviceGuideTextsmall ">* 코드 발급 받는 방법<br/>
                                    [MY PAGE] - 내 서재 - <span className="deviceGuideTextPoint">오큘러스 고 VR APP</span> 클릭 - <span  className="deviceGuideTextPoint">코드 발급받기 </span> 클릭 
                                </span>
                                <br/>
                                <span className="deviceGuideTextsmall">* 발급 받은 다운로드 코드는 계정 1개에만 사용 가능합니다. </span>
                                <span className="deviceGuideTextsmall">* 다른 기기에서도 다운로드를 원하면 해당 계정에 다른 기기를 추가로 페어링 하면 설치 가능합니다. </span>
                            </li>
                        </ul>
                    </li>
                </>
                }
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg15} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>6. 오큘러스 고 실행</span>
                                {instituteUser && 
                                <>
                                <span>(1 ~ 5번 부분은 완료된 상태)</span>
                                <span>오큘러스 계정 : <br/>ID : scnu.tlp@gmail.com<br/>PW : 1234qwer^^</span>
                                {/* <span>오큘러스 계정은 추후 제공</span> */}
                                </>
                                }
                                <span>7. 해당 계정과 페어링 된 오큘러스 고를 작동 시킨 후 [탐색] - [라이브러리]로 이동합니다.</span>
                                <span>8. 라이브러리 창에서 [설치되지 않음] 항목을 선택 후 "어린 왕자, 나를 만나다"가 있는지 확인 후 클릭하여 설치를 진행합니다.</span>
                                <span>9. 설치가 완료되면 [라이브러리] - [앱]에서 확인이 가능합니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg3} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span></span>
                                <span>10. 오큘러스 고 화면 실행</span>
                                <span>화면에 컨트롤러가 가리키는 방향을 나타내는 선이 보입니다.</span>
                                <span>선택할 대상을 가리키고 마우스 클릭 버튼을 누르면 입력 기능 수행됩니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg2} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span></span>
                                <span>[오큘러스 고 컨트롤러 버튼 안내]</span><br />
                                <span><span style={{color:"rgba(255,36,0,0.8)"}}>●</span> 자막 ON/OFF 전환 : 캐릭터들의 대사 자막 표시 설정 (내레이션은 자막은 숨기기 설정을 할 수 없습니다.)</span>
                                <span><span style={{color:"rgba(255,96,0,0.8)"}}>●</span> 마우스 클릭 버튼 : 화면 상의 버튼을 클릭</span>
                                <span><span style={{color:"rgba(255,192,0,0.8)"}}>●</span> 메뉴 버튼 : 어린 왕자 일시 정지 및 오큘러스 고 홈 화면으로 이동</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg16} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>카드보드 이용시</span>
                                <span>1. 어린왕자 VR앱을 다운받습니다.</span>
                                <span><a href='https://www.xrbook.net/xrbook/app_download'> ** 다운로드 페이지로 이동</a></span>
                                <span>2. 스마트폰을 장착하고 카드보드의 버튼 위치를 확인합니다.</span><br />
                                <span>[카드보드 1세대 버튼]</span>
                                <span  className="deviceGuideTextsmall">* 왼쪽 측면의 자석 버튼은 카드보드에서 버튼 입력 기능을 수행합니다.</span>
                                <span  className="deviceGuideTextsmall">* VR 영상 시청 시 자석 버튼을 사용하여 자막 ON/OFF 전환할 수 있습니다.</span>
                                <span>[카드보드 2세대 버튼]</span>
                                <span  className="deviceGuideTextsmall">* 오른쪽 상단의 터치 버튼은 카드보드에서 버튼 입력 기능을 수행합니다.</span>
                                <span  className="deviceGuideTextsmall">* VR 영상 시청 시 터치 버튼을 사용하여 자막 ON/OFF 전환할 수 있습니다.</span>
                              
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg12} alt="기기 설명 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span></span>
                                <span>3. 카드보드 화면 실행</span>
                                <span>정 중앙에 선택 선이 생성되어 있으며 선택할 대상을 가리키고 일정 시간 바라보면 입력 기능이 수행됩니다.</span>
                            </li>
                        </ul>
                    </li>                
                </ul>
                <h5>VR App 진행 방법</h5>
                <ul className="vrMenualGuide">
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg6} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>VR 앱 화면 진행 1</span>
                                <span>앱을 실행하면 현재 로그인 되어있는지 확인하며 계정이 유효하다면 [어린 왕자 나를 만나다 VR] 메인 화면으로 이동합니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg7} alt="기기 화면 이미지"/>
                            </li>
                            {instituteUser ?
                                <li className="deviceGuideText">
                                    <span>VR 앱 화면 진행 2</span>
                                    <span>앱 로그인 계정 : [개인용] 클릭 후</span>
                                    <span>ID : scnu.tlp@gmail.com<br/>PW : 1234qwer</span>
                                    <span>로그인 관련 문의 : sj.lee@newkl.net</span>
                                </li>
                                :
                                <li className="deviceGuideText">
                                <span>VR 앱 화면 진행 2</span>
                                <span>만약 입력된 로그인 정보가 없거나, 유효하지 않은 경우에는 로그인 화면이 나옵니다. 기업 회원의 경우 [기업용]선택 후 '기업이름'과 '시리얼 키'를 각각 ID, 비밀번호 칸에 입력해 주시고, 개인 회원의 경우 [개인용] 선택 후 북 코드를 등록한 https://www.xrbook.net 회원 ID 및 비밀번호를 기입하시면 됩니다.<br/>등록된 프로그램이 대상별로 여러 개인 경우 유효한 프로그램 중 선택할 수 있습니다.</span>
                            </li>
                            }

                            
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg8} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>VR 앱 화면 진행 3</span>
                                <span>메인 화면에 진입하면 가이드를 볼 것인지 여부를 물어봅니다. 시작 시 단 한번만 물어보며, 로그아웃을 하여 데이터가 사라지기 전까지 다시 묻지 않습니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg9} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>VR 앱 화면 진행 4</span>
                                <span>메인 시작 화면에서 원하는 기능의 버튼을 가리키고 클릭하여 실행합니다.</span>
                                <span>- 시작하기 : Chapter 1-1부터 진행</span>
                                <span>- 이어보기 : 원하는 Chapter와 씬을 선택하여진행</span>
                                <span>- 설정 : 자막의 언어를 설정하고 로그아웃 하여 해당 시리얼 키의 캐시 삭제</span>
                                <span>- 종료 : 앱 종료</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg10} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>VR 앱 화면 진행 5</span>
                                <span>이어 하기를 선택하면 챕터 별 씬 중 원하는 장면을 선택하여 시청할 수 있습니다. 씬을 선택하여 각 씬 별 설명을 볼 수 있습니다</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={vrGuideImg11} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>VR 앱 화면 진행 6</span>
                                <span>각 Chapter의 마지막 장면에서 다음 장면을 진행할지 물어봅니다.</span>
                                <span>- 초기화면 : 시작 화면으로</span>
                                <span>- 다음보기 : 다음 Chapter의 1번 Scene으로 이동</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h5>AR App 사용 방법</h5>
                <ul className="arMenualGuide">
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg1} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>AR Activity 진행방법</span>
                                <span>- 앱 다운로드</span>
                                <span><a href='https://www.xrbook.net/xrbook/app_download'>다운로드 페이지로 이동</a></span>
                                {/* <span>ios, 안드로이드 모바일 스토어에서 ‘어린 왕자 나를 만나다 AR’ 검색 후 다운로드</span> */}
                                <br/>
                                <span>1. </span>
                                <span>앱을 실행 후 시작하기 버튼을 누르면 AR앱이 시작됩니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg2} alt="기기 화면 이미지"/>
                            </li>
                            {instituteUser ?
                                <li className="deviceGuideText">
                                    <span>AR Activity 진행방법</span>
                                    <span>앱 로그인 계정 : [개인용] 클릭 후</span>
                                    <span>ID : scnu.tlp@gmail.com<br/>PW : 1234qwer</span>
                                    {/* <span>만약 입력된 로그인 정보가 없거나, 유효하지 않은 경우에는 로그인 화면이 나옵니다. 기업 회원의 경우 [기업용]선택 후 '기업이름'과 '시리얼 키'를 각각 ID, 비밀번호 칸에 입력해 주시고, 개인 회원의 경우 [개인용] 선택 후 북 코드를 등록한 https://www.xrbook.net 회원 ID 및 비밀번호를 기입하시면 됩니다.<br/>등록된 프로그램이 대상별로 여러 개인 경우 유효한 프로그램 중 선택할 수 있습니다.</span> */}
                                </li>
                                :
                                <li className="deviceGuideText">
                                    <span>AR Activity 진행방법</span>
                                    <span>2. </span>
                                    <span>입력된 로그인 정보가 없거나, 유효하지 않은 경우에는 로그인화면이 나오며, 기업 회원의 경우 [기업용]선택 후 '기업이름'과 '시리얼 키'를 각각 ID, 비밀번호 칸에 입력해 주시고, 개인 회원의 경우 [개인용] 선택 후 북 코드를 등록한 https://www.xrbook.net 회원 ID 및 비밀번호를 기입하시면 됩니다.<br/>등록된 프로그램이 대상별로 여러 개인 경우 유효한 프로그램 중 선택할 수 있습니다.</span>
                                    <br/>
                                    <span>3. </span>
                                    <span>시리얼 키 입력이 완료되면, 메인 화면에 진입하며 원하는 AR Activity를 선택하여 감상할 수 있습니다.</span>
                                </li>
                            }

                            
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg3} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>WITH 어린 왕자</span>
                                <span>- 어린 왕자의 별 둘러보기</span>
                                <span>어린 왕자가 살고 있는 별을 AR 증강을 통해 둘러볼 수 있습니다.</span>
                                <br/>
                                <span>- 어린 왕자와 지는 해 보기</span>
                                <span>AR로 증강된 노을을 바라보는 어린 왕자와 함께 노을을 바라보고 사진도 찍어 볼 수 있습니다.</span>
                                <br/>
                                <span>- 어린 왕자 되어 보기</span>
                                <span>어린 왕자가 되어 사진을 찍어볼 수 있습니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg4} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>WITH 행성 사람들</span>
                                <span>- 행성 캐릭터 되어보기</span>
                                <span>각 행성에 살고 있는 캐릭터들의 사진 판넬을 증강시키고 판넬 구멍에 얼굴을 맞춰 행성 사람들이 된 것 처럼 사진을 찍어볼 수 있습니다.</span>
                                <br/>
                                <span>- 행성 커스터마이징</span>
                                <span>나만의 별을 만들어 색과 물건들을 배치하여 꾸며보고 이름도 붙여볼 수 있습니다.</span>
                                <br/>
                                <span>- 행성 사람들과 사진 찍기</span>
                                <span>어린 왕자별, 지구별, 6개의 별들에 살고 있던 모든 사람들과 함께 사진을 찍어볼 수 있습니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg5} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>WITH 지구별 친구</span>
                                <span>- 뱀 찾기</span>
                                <span>사막 모래 속 숨어 있는 뱀을 찾아볼 수 있습니다.</span>
                                <br/>
                                <span>- 양 그려주기</span>
                                <span>지구에 찾아온 어린 왕자에게 어린 왕자가 원하는 양 그림을 그려 줄 수 있습니다 .</span>
                                <br/>
                                <span>- 여우 길들이기</span>
                                <span>여우가 알려주는 길들이기의 방법으로 여우를 찾고 길들일 수 있습니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg6} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>SHARE 나의 어린 왕자</span>
                                <span>- 어린 왕자 카드 만들기</span>
                                <span>어린 왕자 엽서를 만들고 꾸며서 공유하거나, 보낼 수 있습니다. </span>
                                <br/>
                                <span>- 어린 왕자, 캐릭터 그리기</span>
                                <span>AR 공간에 어린 왕자를 그려 볼 수 있습니다.</span>
                                <br/>
                                <span>-Reflcetion Writing Note 공유</span>
                                <span>Reflection Writing Note 작성한 것을 사진으로 찍어 공유하고, 다른 사람들이 쓴 reflection writing note를 볼 수 있습니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg7} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>어린 왕자 오디오북</span>
                                <span>어린 왕자 컨텐츠를 오디오 사운드로 감상할 수 있습니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="deviceGuideImage">
                                <img src={arGuideImg8} alt="기기 화면 이미지"/>
                            </li>
                            <li className="deviceGuideText">
                                <span>어린 왕자 애니메이션 감상하기</span>
                                <span>어린 왕자 컨텐츠를 2D, 3D 애니메이션360으로 감상할 수 있습니다</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
export default  Manual