import React, { Component } from 'react'

class PrivacyPolicy extends Component {
    componentDidMount() {
        try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
      }
    render() {
    return (
        <div className="termPoliBg">
            <div className="termPoliframe">
                <h1>개인정보 처리방침</h1>
                <div className="underBar"></div>
                
                <div className="ag ag00">
                    <div className="uaBox">
                        <ul>
                            <li>뉴클('https://www.xrbook.net'이하 'XR BOOK')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</li>
                            <li>뉴클('XR BOOK') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag01">
                    <h5>제 1 조 개인정보의 처리 목적 </h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 뉴클('https://www.xrbook.net'이하 'XR BOOK' 이하 “당사”라고 합니다)은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.
                                <ul className="detailBx">
                                    <li><span>가. 홈페이지 회원가입 및 관리</span><br/> 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.</li>
                                    <li><span>나. 민원사무 처리</span><br/>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.</li>
                                    <li><span>다. 재화 또는 서비스 제공</span><br/>물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.</li>
                                    <li><span>라. 마케팅 및 광고에의 활용</span><br/>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</li>
                                    <li><span>마. 개인영상정보</span><br/>범죄의 예방 및 수사, 시설안전 및 화재예방, 교통단속, 교통정보의 수집·분석 및 제공 등을 목적으로 개인정보를 처리합니다.</li>
                                </ul>
                            </li>
                            <li>2. 개인정보 수집 항목
                                <table className="detailBx poliGraph1">
                                    <thead>
                                        <tr className="cate">
                                            <th scope="col"><span>구분</span></th>
                                            <th scope="col"><span>유형</span></th>
                                            <th scope="col"><span>항목</span></th>
                                            <th scope="col"><span>이용 목적</span></th>
                                            <th scope="col"><span>수집항목</span></th>
                                            <th scope="col"><span>보유기간</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="cont">
                                            <td rowspan="8"><span>회원</span></td>
                                            <td rowspan="7"><span>필수</span></td>
                                            <td rowspan="2"><span>회원가입</span></td>
                                            <td><span>이용자 식별, 본인확인, 회원 서비스 제공, 고객 고지, 고객상담, 고객불만 접수 및 처리</span></td>
                                            <td><span>성명, 아이디, 비밀번호, 본인확인정보(CI, DI, 생년월일, 성별, 내외국인구분), 아이핀번호(아이핀 본인확인 시), 휴대폰번호, 이메일주소, 주소</span></td>
                                            <td><span>회원탈퇴 시</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>부정이용자확인</span></td>
                                            <td><span>성명, 아이디, 본인확인정보(CI), 휴대폰번호</span></td>
                                            <td><span>회원탈퇴 후 30일까지</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td rowspan="2"><span>주문</span></td>
                                            <td><span>계약사항 및 주문내역 안내</span></td>
                                            <td><span>성명, 휴대폰번호, 이메일주소</span></td>
                                            <td rowspan="5"><span>법정 의무 보유기간까지</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>상품 배송 및 배송지 관리</span></td>
                                            <td><span>수취인 정보(성명, 주소, 휴대폰번호, 전화번호)</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td rowspan="3"><span>결제</span></td>
                                            <td><span>주문 및 예약 상품 결제, 포인트 적립 / 사용, 부정거래확인</span></td>
                                            <td><span>계좌정보(은행명, 예금주, 계좌번호), 신용카드정보(카드사명, 카드번호), 포인트카드정보(제휴사명, 카드번호), 휴대폰정보(통신사, 휴대폰번호)</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>현금영수증 발행</span></td>
                                            <td><span>휴대폰번호, 신용카드번호, 현금영수증카드번호, 사업자번호</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>결제 취소 금액 환불</span></td>
                                            <td><span>계좌 정보(은행명, 예금주명, 계좌번호)</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>선택</span></td>
                                            <td><span>마케팅</span></td>
                                            <td><span>상품 및 서비스 안내, 이벤트 정보 및 혜택 제공</span></td>
                                            <td><span>성명, 휴대폰번호, 이메일주소, 생년월일, 마케팅 수신 동의여부</span></td>
                                            <td><span>회원탈퇴 또는 동의 철회 시</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td rowspan="3"><span>비회원</span></td>
                                            <td rowspan="2"><span>필수</span></td>
                                            <td rowspan="2"><span>문의</span></td>
                                            <td><span>이용자 식별, 본인확인,  고객 문의 접수 및 처리</span></td>
                                            <td><span>문의자 정보(성명, 휴대폰번호, 이메일주소),</span></td>
                                            <td rowspan="3"><span>법정 의무 보유기간까지</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>상품 배송 및 배송지 관리</span></td>
                                            <td><span>수취인 정보(성명, 주소, 휴대폰번호, 전화번호)</span></td>
                                        </tr>
                                        <tr className="cont">
                                            <td><span>선택</span></td>
                                            <td><span>마케팅</span></td>
                                            <td><span>상품 및 서비스 안내, 이벤트 정보 및 혜택 제공</span></td>
                                            <td><span>성명, 휴대폰번호, 이메일주소, 생년월일, 마케팅 수신 동의여부</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                            <li>3. 개인정보의 수집방법
                                <ul className="detailBx">
                                    <li>- 홈페이지, 상담게시판, 전화, 팩스, 이벤트응모, 배송 요청</li>
                                    <li>- 로그 분석 프로그램을 통한 생성정보수집, '쿠키(cookie)'에 의한 정보 수집</li>
                                    <li>- 관계사 및 구매자로부터의 제공</li>
                                    <li>- 스마트폰용 어플리케이션을 통한 수집</li>
                                </ul>
                            </li>
                            <li>4. 허위 정보 입력 시 당사의 조치
                                <p>회원은 자신의 정보에 대해 정확성 및 적법성을 보장해야 합니다. 만약 이를 위반하여 타인의 정보를 도용하는 등 각종 방법으로 허위 정보를 입력할 경우 당사는 해당 회원을 관계법령에 따라 신고 할 수 있으며 강제 탈퇴를 시킬 수도 있습니다.</p>
                            </li>
                            <li>5. 회원이 자발적으로 공개한 개인정보로 인해 회원 개인에게 발생하는 손실이나 문제는 전적으로 개인의 책임이며, 공개적인 공간에 게재되는 개인정보는 다른 사람이 수집하여 무단으로 사용할 수 있음을 인지하시어, 이로 인해 원하지 않는 피해를 입을 수 있음을 유념하시기 바랍니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag02">
                    <h5>제 2 조 개인정보의 보유, 이용기간 및 파기</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 당사는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 회원의 개인정보를 지체 없이 파기하고 있으며, 부정 사용자 이용 제한을 위해 최소한의 개인정보를 30일 동안 보관합니다. 단. 전자상거래법, 통신비밀보호법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 관리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
                                <ul className="detailBx">
                                    <li>가. 계약 또는 청약철회 등에 관한 기록 : 5년</li>
                                    <li>나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
                                    <li>다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                                    <li>라. 웹사이트 방문 기록 : 3개월</li>
                                </ul>
                            </li>
                            <li>2. 개인정보 파기방법은 아래와 같습니다.
                                <ul className="detailBx">
                                    <li>가. 종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각</li>
                                    <li>나. 전자적 파일형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</li>
                                </ul>
                            </li>
                            <li>3. 개인정보 유효기간 제도 도입
                                <ul className="detailBx">
                                    <li>가. 당사는 장기간(1년 이상) 서비스 미 이용자의 개인정보보호를 위하여 다른 이용자의 개인정보와 분리하여 저장, 관리합니다.</li>
                                    <li>나. 미이용의 기간은 로그인, 주문/결제 등으로 산정하며 법령에서 정의한 기간 동안 서비스 미이용자에 대하여 분리, 저장 관리합니다.</li>
                                    <li>다. 당사는 미이용자 개인정보 분리/저장시점 도래 1개월 이전에 이메일 등을 통해 해당 이용자에게 관련 내용을 공지합니다.</li>
                                    <li>라. 분리, 저장된 미이용자 개인정보는 관련 법령에 따라 일정기간 동안 보유하며, 해당 기간 종료 후 파기됩니다. 파기되지 않은 개인정보는 해당 이용자의 요청에 따라 서비스 이용을 재개하는 시점에 다시 제공됩니다.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag03">
                    <h5>제 3 조 개인정보의 제3자 제공에 관한 사항</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 당사는 고객의 개인정보를 제2조(개인정보의 수집항목 및 이용목적)에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 사전 동의 없이 타인 또는 타기업•기관에 제공하지 않습니다.</li>
                            <li>2. 회원의 개인정보를 제공하거나 공유하는 경우에는 제공받는 자, 제공목적, 제공항목, 보유/이용기간 등에 대해 개별적으로 사이트, 전자우편 또는 서면, 신청서 등을 통해 고지한 후 이에 대하여 별도 동의를 구합니다.</li>
                            <li>3. 다만 다음 사항은 예외적으로 동의 없이 개인정보 제공이 가능합니다.
                                <ul className="detailBx">
                                    <li>가. 수사목적으로 관계법률에서 정한 절차와 방법에 따라 수사기관이 개인정보 제공을 요구하는 경우</li>
                                    <li>나. 통계작성, 학술연구나 시장조사 등을 위하여 특정 개인을 식별할 수 없는 형태로 광고주, 협력사나 연구단체 등에 제공하는 경우</li>
                                    <li>다. 기타 관계법률의 규정에 따른 요청이 있는 경우</li>
                                    <li>라. 영업의 양도ㆍ합병 등</li>
                                    <li>※ 회사는 영업의 양도 등에 관한 사유가 발생하여 회원의 개인정보 이전이 필요한 경우, 관계법률에서 규정한 절차와 방법에 따라 개인정보 이전에 관한 사실 등을 사전에 고지하며, 회원에게는 개인정보 이전에 관한 동의 철회권을 부여 합니다.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag04">
                    <h5>제 4 조 정보주체와 법정대리인의 권리·의무 및 그 행사방법 </h5>
                    <div className="uaBox">
                        <ul>
                            <li>이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</li>
                            <li>1. 정보주체는 “당사”에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.
                                <ul className="detailBx">
                                    <li>① 당사가 가지고 있는 회원의 개인정보</li>
                                    <li>② 당사가 회원의 개인정보를 이용하거나 제3자에게 제공한 현황</li>
                                    <li>③ 회원이 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 한 현황</li>
                                </ul>
                            </li>
                            <li>2. 제1항에 따른 권리 행사는 “당사”에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 “당사”는 이에 대해 지체 없이 조치하겠습니다.
                                <ul className="detailBx">
                                    <li>①. 홈페이지 : 로그인 후 마이페이지에서 ‘정보수정/탈퇴’를 클릭 하여 개인정보 열람, 정정 또는 탈퇴가 가능합니다.</li>
                                    <li>②. 개인정보보호책임자 또는 고객센터로 서면(서울시 송파구 송파대로 201 송파테라타워2 B동 613호), 전화(070-7410-0412) 또는 이메일(dr.park@newkl.net)을 통해 연락하시면 지체 없이 조치하겠습니다.</li>
                                </ul>
                            </li>
                            <li>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
                            <li>4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
                            <li>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                            <li>6. “당사”는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
                            <li>7. 회원이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 조치하겠습니다.</li>
                            <li>8. 당사는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 당사가 수집하는 개인정보의 보유 및 이용 기간에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag05">
                    <h5>제5조. 개인정보 자동 수집 장치의 설치•운영 및 거부</h5>
                    <div className="uaBox">
                        <p>더에이치알더는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키’를 사용하지 않습니다.</p>
                    </div>
                </div>
                <div className="ag ag06">
                    <h5>제6조. 개인정보 보호책임자</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 뉴클(‘https://www.xrbook.net’이하 ‘XR BOOK) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                                <ul className="detailBx poliGraph2">
                                    <li><span className="cate">구분</span><span className="manager">담당</span></li>
                                    <li><span className="cate">개인정보보호책임자(CPO)</span><span className="manager">이소진</span></li>
                                    <li><span className="cate">직책</span><span className="manager">PM</span></li>
                                    <li><span className="cate">전화 번호</span><span className="manager">02-9288-9009</span></li>
                                    <li><span className="cate">전자 우편 주소</span><span className="manager">sj.lee@newkl.net</span></li>
                                </ul>
                            </li>
                            <li>2. 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.
                                <ul className="detailBx">
                                    <li>- 개인정보침해신고센터 (http://privacy.kisa.or.kr/ 국번없이 118)</li>
                                    <li>- 개인정보분쟁조정위원회 (http://www.kopico.go.kr/ 1833-6972)</li>
                                    <li>- 대검찰청 사이버수사과 (http://www.spo.go.kr / 02-3480-3570)</li>
                                    <li>- 경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 경찰민원콜센터 182)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag07">
                    <h5>제7조. 개인정보의 안전성 확보 조치 </h5>
                    <div className="uaBox">
                        <ul>
                            <li>뉴클('XR BOOK')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</li>
                            <li><span>1. 정기적인 자체 감사 실시</span><br/>개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</li>
                            <li><span>2. 개인정보 취급 직원의 최소화 및 교육</span><br/>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</li>
                            <li><span>3. 내부관리계획의 수립 및 시행</span><br/>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
                            <li><span>4. 해킹 등에 대비한 기술적 대책</span><br/>뉴클('XR BOOK')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</li>
                            <li><span>5. 개인정보의 암호화</span><br/>이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</li>
                            <li><span>6. 접속기록의 보관 및 위변조 방지</span><br/>개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.</li>
                            <li><span>7. 개인정보에 대한 접근 제한</span><br/>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</li>
                            <li><span>8. 문서보안을 위한 잠금장치 사용</span><br/>개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</li>
                            <li><span>9. 비인가자에 대한 출입 통제</span><br/>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag08">
                    <h5>제8조. 고지의 의무</h5>
                    <div className="uaBox">
                        <ul>
                            <li>현 개인정보처리방침은 2020년 10월 05일에 개정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지를 통해 고지할 것입니다.</li>
                            <li>개인정보처리방침 시행일자 : 2020년 10월 05일</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};
}
export default PrivacyPolicy;