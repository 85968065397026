import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import * as ROUTES from '../../../../constants/routes';
import './XRservice.css'
import './XRserviceRes.css'
import $ from "jquery";
import MainSwiper from '../MainSwiper.jsx'
import AuthDownloadBtn from '../../../Utils/AuthDownloadBtn'

class XRservice extends Component {
    state={
        index:0,
        bgImgUrl:'',
        slideList:[]
    }
    // componentDidMount(){
    //     this.getxrMakingInfo()
    // }
    setIncrement = () => {
        this.props.firebase.tlpStatistics().update({
            TotalXRBOOKbrochure : this.props.firebase.fieldValue.increment(1)
        })
        .catch(err=> {
            console.log(err)
        })
    }

    getxrMakingInfo = () => {
        this.props.firebase.mainSection('xrMaking').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            console.log(err)
            // window.location.reload()
        })
    }
render(){
    const { xrbookByTheHRDer } = this.props
    const {slideList} = this.state
    let theme = "black"

    $(".xrs3thInFrame .contBox>ul>li").mouseover(function(){
        $(".xrs3thInFrame .contBox>ul>li").find("ul").css("background", "rgba(0,0,0,0.5)");
        $(".xrs3thInFrame .contBox>ul>li").css("width", "10%");  
        $(this).css("width", "80%"); 
        $(".xrs3thInFrame .programCase>span>ul>li").css("opacity", "0"); 
        $(this).find("ul>li").css("opacity", "1"); 
        $(this).find("ul").css("background", "rgba(0,0,0,0.5)");
        
	});
	$(".xrs3thInFrame .contBox>ul>li").mouseout(function(){
        $(".xrs3thInFrame .contBox>ul>li").find("ul").css("background", "rgba(0,0,0,0.5)");
        $(this).css("width", "80%"); 
        $(".xrs3thInFrame .programCase>span>ul>li").css("opacity", "0"); 
        $(this).find("ul>li").css("opacity", "1"); 
        $(this).find("ul").css("background", "rgba(0,0,0,0.2)");
    });
    

    $(".xrs4thInFrame .contBox>ul>li").mouseover(function(){
        $(".xrs4thInFrame .contBox>ul>li").find("ul").css("background", "rgba(0,0,0,0.5)");
        $(".xrs4thInFrame .contBox>ul>li").css("width", "10%");  
        $(this).css("width", "80%"); 
        $(".xrs4thInFrame .programCase>span>ul>li").css("opacity", "0"); 
        $(this).find("ul>li").css("opacity", "1"); 
        $(this).find("ul").css("background", "rgba(0,0,0,0.5)");
	});
	$(".xrs4thInFrame .contBox>ul>li").mouseout(function(){
        $(".xrs4thInFrame .contBox>ul>li").find("ul").css("background", "rgba(0,0,0,0.5)");
        $(this).css("width", "80%"); 
        $(".xrs4thInFrame .programCase>span>ul>li").css("opacity", "0"); 
        $(this).find("ul>li").css("opacity", "1"); 
        $(this).find("ul").css("background", "rgba(0,0,0,0.2)");
	});


return (
    <React.Fragment>
        <div className="sectOutFrame xrsOutFrame">
            {/* <MainSwiper slideList={slideList} idName='xrMakingSlide' xrbookByTheHRDer={xrbookByTheHRDer} theme={theme}/>          */}
            <div className="xrsInFrame xrs1stInFrame">
                <div className="contBox"></div>
                <div className="TitleBox">
                    <ul>
                        <li className="contTitleText"><span>NEWKL의</span><span>XR BOOK</span></li>
                        <li className="contentText">
                            <span>NEWKL의  XR BOOK은</span>
                            <span>AR(증강현실), VR(가상현실), MR(혼합현실)에서 </span>
                            <span>Web AR, VR등으로 기술적 확장과 2D, 3D 애니메이션, 이미지, 영상등 다양한 형식의 콘텐츠가 유기적으로 통합된 콘텐츠이며,</span>
                            <span>XR기반의 학습&성과 플랫폼의 브랜드입니다</span>
                            <br/>
                            <span>* XR BOOK은 뉴클 출원중인 상표입니다</span>
                            <span>혁신, XR BOOK으로 시작하십시오.</span>
                        </li>
                        <li className="buttonText"><a href={xrbookByTheHRDer} target='_blank' onClick={()=>this.setIncrement()} download="XRBOOK소개서_뉴클" >XR BOOK 소개서 다운로드</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="sectOutFrame xrsOutFrame">
            <div className="xrsInFrame xrs2ndInFrame">
                <div className="contBox">
                    <div></div>
                </div>
                <div className="TitleBox">
                    <ul>
                        <li className="contTitleText"><span>뉴클㈜</span><span>XR BOOK Making Service</span></li>
                        <li className="contentText">
                            <span>효과적인 고객 커뮤니케이션을 위한 User Experience Kit 개발과</span>
                            <span>XR BOOK 기반의 학습 프로그램을 개발, 운영을 제공하는 서비스입니다.</span>
                            <br/>
                            <span>AR(증강현실), VR(가상현실), MR(혼합현실)을 기반으로</span>
                            <span>Web AR, VR등으로 기술적 확장과 2D, 3D 애니메이션, 이미지, 영상 등</span>
                            <span>다양한 형식의 콘텐츠가 유기적으로 통합된 XR BOOK을 개발 합니다.</span>
                            <br/>
                            <span>조직의 교육과 고객 커뮤니케이션, 마케팅, 세일즈, 사회공헌 등과</span>
                            <span>개인의 기념, 선물 등으로 다양한 영역에서 혁신적인 사용자 경험을 제공합니다.</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="sectOutFrame xrsOutFrame">
            <div className="xrsInFrame xrs3thInFrame">
                <div className="contBox">
                    <ul>
                        <li className="programCase programCase1">
                            <span>
                                <ul>
                                    <li>XR BOOK <br/>학습을 혁신합니다.</li>
                                    <li>
                                        <span>#임원교육</span>
                                        <span>#리더십교육</span>
                                        <span>#시니어교육</span>
                                        <span>#회사소개교육</span>
                                    </li>
                                    <li>"우리는 XR BOOK으로 학습한다"</li>
                                </ul>
                            </span>
                        </li>
                        <li className="programCase programCase2">
                            <span>
                                <ul>
                                    <li>XR BOOK <br/>학습을 혁신합니다.</li>
                                    <li>
                                        <span>#팀빌딩교육</span>
                                        <span>#서비스교육</span>
                                        <span>#상품교육</span>
                                        <span>#조직문화</span>
                                        <span>#기술교육</span>
                                        <span>#조직활성화교육</span>
                                    </li>
                                    <li>"우리는 XR BOOK으로 학습한다"</li>
                                </ul>
                            </span>
                        </li>
                        <li className="programCase programCase3">
                            <span>
                                <ul>
                                    <li>XR BOOK <br/>학습을 혁신합니다.</li>
                                    <li>
                                        <span>#핵심가치교육</span>
                                        <span>#신입사원 교육</span>
                                        <span>#전직교육</span>
                                        <span>#고객서비스</span>
                                    </li>
                                    <li>"우리는 XR BOOK으로 학습한다"</li>
                                </ul>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="TitleBox">
                    <ul>
                        <li className="sectMenuText">XR BOOK Making Service</li>
                        <li className="contTitleText"><span>Learning Program</span><span>Development</span></li>
                        <li className="contentText">
                            <span>XR BOOK 학습 플랫폼을 통해 </span>
                            <span>온,오프 라인 교육과정 개발 및 운영을</span>
                            <span>제공합니다.</span>
                        </li>
                        <li className="buttonText">
                            <a href={ROUTES.TLP_MAIN_CONTACT}>문의하기</a>
                            <a href={xrbookByTheHRDer} target='_blank' onClick={()=>this.setIncrement()} download="XRBOOK소개서_뉴클">XR BOOK 소개서 다운로드</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="sectOutFrame xrsOutFrame">
            <div className="xrsInFrame xrs4thInFrame">
                <div className="contBox">
                    <ul>
                        <li className="programCase programCase1">
                            <span>
                                <ul>
                                    <li>XR BOOK <br/>성과를 혁신합니다.</li>
                                    <li>
                                        <span>#교재</span>
                                        <span>#회사소개</span>
                                        <span>#서비스소개</span>
                                        <span>#상품소개</span>
                                        <span>#사보</span>
                                        <span>#창업자</span>
                                    </li>
                                    <li>"우리 고객은 XR BOOK으로 경험한다"</li>
                                </ul>
                            </span>
                        </li>
                        <li className="programCase programCase2">
                            <span>
                                <ul>
                                    <li>XR BOOK <br/>성과를 혁신합니다.</li>
                                    <li>
                                        <span>#친구 선물</span>
                                        <span>#부모님 선물</span>
                                        <span>#특별한 선물</span>
                                    </li>
                                    <li>"우리 고객은 XR BOOK으로 경험한다"</li>
                                </ul>
                            </span>
                        </li>
                        <li className="programCase programCase3">
                            <span>
                                <ul>
                                    <li>XR BOOK <br/>성과를 혁신합니다.</li>
                                    <li>
                                        <span>#초대장</span>
                                        <span>#기념품</span>
                                        <span>#퇴임선물</span>
                                        <span>#행사</span>
                                    </li>
                                    <li>"우리 고객은 XR BOOK으로 경험한다"</li>
                                </ul>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="TitleBox">
                    <ul>
                        <li className="sectMenuText">XR BOOK Making Serivce</li>
                        <li className="contTitleText"><span>User Experience Kit</span><span>Development</span></li>
                        <li className="contentText">
                            <span>종이 형태의 XR BOOK과 </span>
                            <span>Digital XR BOOK으로 목적에 맞게 </span>
                            <span>개발, 운영 합니다.</span> 
                        </li>
                        <li className="buttonText">
                            <a href={ROUTES.TLP_MAIN_CONTACT}>문의하기</a>
                            <a href={xrbookByTheHRDer} target='_blank' onClick={()=>this.setIncrement()} download="XRBOOK소개서_뉴클">XR BOOK 소개서 다운로드</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </React.Fragment>
);
}
}

export default withFirebase(XRservice)
