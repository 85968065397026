import React, { Component } from 'react'
import * as ROUTES from '../../constants/routes'
import {Link} from 'react-router-dom'
import './Terms&Policies.css'

class TermsOfService extends Component {
    componentDidMount() {
        try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
      }
    render() {
    return (
        <div className="termPoliBg">
            <div className="termPoliframe">
                <h1>이용약관</h1>
                <div className="underBar"></div>

                <div className="ag ag01">
                    <h4>제 1 장 총칙</h4>
                    <h5>제 1 조 (목적)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>본 약관은 뉴클 주식회사(이하 당사라 한다)에서 운영하는 XR BOOK site(https://www.xrbook.net)(이하 “XR북”이라 한다)에서 제공하는 온라인상의 인터넷 서비스(이하 서비스라 하며, 접속 가능한 유·무선 단말기의 종류와는 상관없이 이용 가능한 당사가 제공하는 모든 서비스를 의미합니다)를 이용함에 있어서 회사와 이용자의 권리, 의무, 책임 사항 및 서비스 이용절차 등에 관한 사항을 규정함을 목적으로 합니다.</li>
                            <li>※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다」</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag02">
                    <h5>제 2 조 (정의)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"이란 당사가 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기 위하여 컴퓨터, TV, 모바일 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.</li>
                            <li>2. "이용자"란 "XR북"에 접속하여 이 약관에 따라 "XR북"이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
                            <li>3. "회원"이라 함은 "XR북"에 회원 등록을 한 자로서, "XR북"이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</li>
                            <li>4. "비회원"이라 함은 회원에 가입하지 않고 "XR북"이 제공하는 서비스를 이용하는 자를 말합니다.</li>
                            <li>5. 아이디(ID)라 함은 회원의 식별과 서비스이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 숫자의 조합을 의미합니다.</li>
                            <li>6. 비밀번호라 함은 이용자의 동일성 확인과 이용자의 권익 및 비밀보호를 위해 이용자 스스로가 설정하여 등록한 문자, 숫자, 특수문자의 조합을 의미합니다.</li>
                            <li>7. 디지털상품이라 함은 동영상, eBook, e러닝, 음원과 같이 VOD, 스트리밍, 다운로드 등의 형태로 제공되는 무배송 상품을 말합니다.</li>
                            <li>8. 게시물이라 함은 이용자가 서비스를 이용함에 있어 서비스 상에 게시한 부호·문자·음성·음향·화상·동영상 등 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</li>
                            <li>9. 본 조에 정의되지 않은 용어는 일반적인 상관례에 따릅니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag03">
                    <h5>제 3 조 (적용범위 및 효력 우선순위)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 본 약관에서 제1장(총칙), 제3장(기타사항)은 전체 서비스에 적용되며, 제2장(통신판매중개)은 당사가 제공하는 서비스 중 통신판매중개서비스에 국한하여 적용됩니다.</li>
                            <li>2. 제1장 및 제3장의 규정과 제2장의 규정이 상충할 때에는 개별서비스 관련 규정인 제2장이 전체서비스 관련 규정인 제1장 및 제3장보다 우선적인 효력을 갖습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag04">
                    <h5>제 4 조 (약관 등의 명시와 설명 및 개정)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보보호책임자 등을 이용자가 쉽게 알 수 있도록 "XR북"의 초기 서비스 화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.</li>
                            <li>2. "XR북"은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약 철회, 배송 책임, 환불 조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결 화면 또는 팝업 화면 등을 제공 하여 이용자의 확인을 구하여야 합니다.
                                <br/>
                                <Link to={ROUTES.SERVICE_CENTER} className="serviceGuideLink">※ 청약 철회, 배송, 환불에 대한 안내 보기</Link>
                            </li>
                            <li>3. "XR북"은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                            <li>4. "XR북"이 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 "XR북"의 초기 화면에 그 적용 일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예 기간을 두고 공지합니다. 이 경우 "XR북"은 개정전 내용과 개정후 내용을 명확히 비교하여 이용자가 알기 쉽도록 표시합니다.</li>
                            <li>5. "XR북"이 개정약관을 공지 또는 통지하면서 회원에게 일정 기한 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 따로 공지 또는 고지하였음에도 회원이 명시적으로 거부의사를 표시하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다. 또한, 회원이 개정약관의 적용에 동의하지 않는 경우 "XR북"은 개정약관의 내용을 적용할 수 없으며, 이 경우, 회원은 이용계약을 해지할 수 있습니다. 다만, 기존약관을 적용할 수 없는 특별한 사정이 있는 경우에는 "XR북"은 이용계약을 해지할 수 있습니다.</li>
                            <li>6. "XR북"이 약관을 개정할 경우에는 그 개정 약관은 그 적용 일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관 조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정 약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정 약관의 공지 기간 내에 "XR북"에 송신하여 "XR북"의 동의를 받은 경우에는 개정 약관 조항이 적용됩니다.</li>
                            <li>7. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에관한법률, 약관의규제등에관한법률, 공정거래위원회가 정하는 전자상거래등에서의소비자보호지침 및 관계 법령 또는 상관례에 따릅니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag05">
                    <h5>제 5 조 (서비스의 제공 및 변경)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1.  "XR북"은 다음과 같은 업무를 수행합니다.
                                <ul className="detailBx">
                                    <li>(1) 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결</li>
                                    <li>(2) 구매 계약이 체결된 재화 또는 용역의 배송</li>
                                    <li>(3) 재화 등의 거래를 위한 통신판매중개서비스 및 이에 수반되는 서비스</li>
                                    <li>(4) 플랫폼 개발 및 운영 서비스</li>
                                    <li>(5) 기타 "XR북"이 정하는 업무</li>
                                </ul>
                            </li>
                            <li>2. "XR북"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공 할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공 일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.</li>
                            <li>3. "XR북"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 본 약관 8조에서 정한 방법 등으로 즉시 통지 합니다.</li>
                            <li>4. 전항의 경우 "XR북"은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "XR북"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag06">
                    <h5>제 6 조 (서비스의 중단)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 컴퓨터 등 전기통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
                            <li>2. "XR북"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, "XR북"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                            <li>3. 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "XR북"은 제9조에 정한 방법으로 이용자에게 통지하고 당초 "XR북"에서 제시한 조건에 따라 소비자에게 보상 합니다. 다만, "XR북"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "XR북"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag07">
                    <h5>제 7 조 (회원가입)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 회원으로 가입하고자 하는 이용자는 "XR북"이 정한 가입 양식에 정해진 사항을 기입한 후 본 약관과 "개인정보의 수집, 제공 및 활용 동의서"에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.</li>
                            <li>2. "XR북"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
                                <ul className="detailBx">
                                    <li>(1) 가입 신청자가 이 약관 제8조 제3항에 의하여 이전에 회원 자격을 상실한 적이 있는 경우, 다만 제8조 제3항에 의한 회원 자격 상실 후 3년이 경과한 자로서 "XR북"의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.</li>
                                    <li>(2) 등록 내용에 허위, 기재 누락, 오기가 있는 경우</li>
                                    <li>(3) 기타 회원으로 등록하는 것이 "XR북"의 기술상 현저히 지장이 있다고 판단되는 경우.</li>
                                    <li>(4) 회원가입 신청일 현재 만 14세 미만인 경우</li>
                                </ul>
                            </li>
                            <li>3. 회원 가입 계약의 성립 시기는 "XR북"의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                            <li>4. 회원은 회원가입 시 등록 사항에 변경이 있는 경우, 상당한 기간 이내에 "XR북"에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag08">
                    <h5>제 8 조 (회원 탈퇴 및 자격 정지 등)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 회원은 "XR북"에 언제든지 탈퇴를 요청할 수 있으며 "XR북"은 즉시 회원 탈퇴를 처리합니다.</li>
                            <li>2. 회원이 다음 각 호의 사유에 해당하는 경우, "XR북"은 회원 자격을 제한 및 정지시킬 수 있습니다.
                                <ul className="detailBx">
                                    <li>(1) 회원이 사망한 경우</li>
                                    <li>(2) "XR북"을 이용하여 구입한 재화 등의 대금, 기타 "XR북" 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
                                    <li>(3) 가입 신청 시 허위 내용을 등록한 경우</li>
                                    <li>(4) 다른 사람의 ID와 비밀번호 또는 그 개인정보를 도용하는 경우</li>
                                    <li>(5) 다음 각 호에 해당하는 행위를 하거나, 기타 "XR북"을 이용하여 법령 또는 이 약관이 금지하거나 공서 양속에 반하는 행위를 하는 경우
                                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;가. "XR북" 화면에 다른 사람의 명예를 훼손하거나 다른 사람의 "XR북" 이용을 방해하는 행위
                                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;나. "XR북" 화면에 음란물을 게제하거나 음란사이트를 링크하는 행위
                                    </li>
                                    <li>(6) 다음 각 호에 해당하는 행위를 하거나, 기타 XR북의 정상적인 영업활동 및 서비스 운영을 고의로 방해하는 행위를 하는 경우
                                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;가. "XR북" 의 이용과정에서 "XR북"의 직원에게 폭언, 협박 또는 음란한 언행 등을 하는 행위
                                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;나. "XR북" 을 통해 재화 등을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소, 반품하여 업무를 방해하는 행위
                                    </li>
                                </ul>
                            </li>
                            <li>3. "XR북"이 회원 자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정 되지 아니하는 경우 "XR북"은 회원 자격을 상실시킬 수 있습니다.</li>
                            <li>4. "XR북"이 회원 자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag09">
                    <h5>제 9 조 (회원 및 비회원에 대한 통지)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"이 회원에 대한 통지를 하는 경우, 회원이 "XR북"과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.</li>
                            <li>2. "XR북"은 불특정 다수 회원에 대한 통지의 경우 1주일 이상 "XR북" 게시판에 게시함으로써 개별 통지에 갈음 할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.</li>
                            <li>3. "XR북"이 비회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자가 제공한 전자우편, SMS 등으로 통지할 수 있으며, 불특정 다수 비회원에 대한 통지의 경우 제2항에 따라 통지할 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag10">
                    <h5>제 10 조 (구매신청)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. XR북" 이용자는 "XR북"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "XR북"은 이용자가 구매를 신청함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
                                <ul className="detailBx">
                                    <li>(1) 재화 등의 검색 및 선택</li>
                                    <li>(2) 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                                    <li>(3) 약관 내용, 청약 철회권이 제한되는 서비스, 배송료, 설치비등의 비용 부담과 관련한 내용에 대한 확인</li>
                                    <li>(4) 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예. 마우스 클릭)</li>
                                    <li>(5) 재화 등의 구매 신청 및 이에 관한 확인 또는 "XR북"의 확인에 대한 동의</li>
                                    <li>(6) 결제 방법의 선택</li>
                                    <li>(7) 기타 "XR북"이 별도로 정하는 절차</li>
                                </ul>
                            </li>
                            <li>2. "XR북"이 제3자에게 구매자 개인정보를 제공•위탁할 필요가 있는 경우 실제 구매신청 시 구매자의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 "XR북"은 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유‧이용 기간 등을 구매자에게 명시하여야 합니다. 다만 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한 개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag11">
                    <h5>제 11 조 (계약의 성립)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 제10조와 같은 구매 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정 대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                                <ul className="detailBx">
                                    <li>(1) 신청 내용에 허위, 기재 누락, 오기가 있는 경우</li>
                                    <li>(2) 상행위(재판매)를 목적으로 구매하는 거래이거나, 거래 정황상 상행위(재판매)를 목적으로 한 구매로 판단되는 경우</li>
                                    <li>(3) 기타 구매 신청에 승낙하는 것이 "XR북" 기술상 현저히 지장이 있다고 판단하는 경우</li>
                                </ul>
                            </li>
                            <li>2. "XR북"의 승낙이 제13조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</li>
                            <li>3. "XR북"의 승낙의 의사 표시에는 이용자의 구매 신청에 대한 확인 및 판매 가능 여부, 구매 신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag12">
                    <h5>제 12 조 (지급 방법)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>"XR북"에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 각 호 의 방법 중 가용한 방법으로 할 수 있습니다. 단, 회사에서 현재 서비스 중인 것에 한하며, "XR북"은 이용자의 지급 방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
                                <ul className="detailBx">
                                    <li>(1) 온라인 무통장 입금, 폰뱅킹, 인터넷 뱅킹, 메일 뱅킹 등의 각종 계좌 이체</li>
                                    <li>(2) 선불 카드, 직불 카드, 신용카드 등의 각종 카드 결제</li>
                                    <li>(3) 전자 화폐에 의한 결제</li>
                                    <li>(4) 마일리지 등 "XR북"이 지급한 포인트에 의한 결제</li>
                                    <li>(5) "XR북"과 계약을 맺었거나 "XR북"이 인정한 상품권에 의한 결제</li>
                                    <li>(6) 기타 전자적 지급 방법에 의한 대금 지급 등</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag13">
                    <h5>제 13 조 (수신 확인 통지, 구매 신청 변경 및 취소)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 이용자의 구매 신청이 있는 경우 이용자에게 수신 확인 통지를 합니다.</li>
                            <li>2. 수신 확인 통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후 즉시 구매 신청 변경 및 취소를 요청할 수 있고 "XR북"은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제16조 청약 철회 등에 관한 규정에 따릅니다.</li>
                            <li>3. 회사는 이용자가 구매(주문) 신청 후 제17조에 의한 지급방법으로 그 신청일로부터 7일 이내 상품대금에 대해 입금이 확인되지 않을 경우 회사는 별도 의사 표시 없이 구매계약을 해제하는 것으로 처리할 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag14">
                    <h5>제 14 조 (재화 등의 공급)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 이용자와 재화 등의 공급 시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만 "XR북"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 다만, 소비자와 "XR북"간 재화 등의 공급 시기에 관하여 별도의 약정이 있는 경우에는 그러하지 아니합니다. 이때 "XR북"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.</li>
                            <li>2. "XR북"은 이용자가 구매한 재화에 대해 배송 수단, 수단별 배송 비용 부담자, 수단별 배송 기간 등을 명시 합니다. 만약 "XR북"이 약정 배송 기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 "XR북"이 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.</li>
                            <li>3. "XR북"은 구매결정 기간 내에 구매결정, 교환 또는 반품에 대한 의사표시가 없는 경우에는 해당 거래에 대하여 이용자의 구매결정 의사표시가 있는 것으로 간주하여 자동구매결정으로 처리할 수 있습니다.</li>
                            <li>4. 공휴일 및 기타 휴무일 또는 날씨 및 택배사 등의 불가항력적 사유가 발생하는 경우 그 해당기간은 배송소요기간에서 제외합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag15">
                    <h5>제 15 조 (환급)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>"XR북"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag16">
                    <h5>제 16 조 (청약 철회 등)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"과 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.</li>
                            <li>2. 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
                                <ul className="detailBx">
                                    <li>(1) 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우 (다만 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약 철회를 할 수 있음)</li>
                                    <li>(2) 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
                                    <li>(3) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우</li>
                                    <li>(4) 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우</li>
                                    <li>(5) 용역 또는 문화산업진흥 기본법 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우(다만 가분적 용역 및 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 아니한다)</li>
                                    <li>(6) 기타 재화 등의 특성상 환불이나 교환을 요청할 수 없는 합리적인 사유가 있는 경우</li>
                                    <li>(7) 주문에 따라 개별적으로 생산되는 물품 등 사전에 해당 거래에 대하여 별도로 고지하고 이용자의 서면(전자문서포함)동의를 받은 경우</li>
                                </ul>
                            </li>
                            <li>3. 제2항 제2호 내지 제4호의 경우에는 "XR북"이 사전에 청약 철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용 상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약 철회 등이 제한되지 않습니다.</li>
                            <li>4. 이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시, 광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약 철회 등을 할 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag17">
                    <h5>제 17 조 (청약 철회 등의 효과)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급 합니다. 이 경우 "XR북"이 이용자에게 재화 등의 환급을 지연한 때에는 그 지연 기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는 지연 이자율을 곱하여 산정한 지연 이자(이하 "지연 배상금"이라 한다)를 지급합니다.</li>
                            <li>2. "XR북"은 위 대금을 환급함에 있어서 이용자가 신용카드 그밖에 대통령령이 정하는 결제 수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제 수단을 제공한 사업자로 하여금 재화 등의 대금 청구를 정지 또는 취소하도록 요청합니다.</li>
                            <li>3. 청약 철회 등의 경우 공급 받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "XR북"은 이용자에게 청약 철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시, 광고 내용과 다르거나 계약 내용과 다르게 이행되어 청약 철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 "XR북"이 부담합니다.</li>
                            <li>4. 이미 재화 등이 일부 사용 또는 일부 소비된 경우, "XR북"은 그 재화 등의 사용 또는 일부 소비에 의하여 이용자가 얻은 이익 또는 그 재화 등의 공급에 소요된 비용에 상당하는 금액으로서 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제24조에서 정하는 범위의 금액의 지급을 이용자에게 청구할 수 있습니다.</li>
                            <li>5. 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "XR북"은 청약 철회 시 그 비용을 누가 부담하는지를 알기 쉽도록 명확하게 표시합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag18">
                    <h5>제 18 조 (개인정보보호)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. XR북"은 이용자의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</li>
                            <li>2. "XR북"은 회원 가입 시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</li>
                            <li>3. XR북"이 이용자의 개인 정보를 수집•이용하는 때에는 반드시 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</li>
                            <li>4. "XR북"은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용•제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</li>
                            <li>5. "XR북"이 제2항과 제3항에 의해 이용자의 동의를 받아야만 하는 경우에는 개인정보보호책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받는자, 제공목적 및 제공할 정보의 내용)등 정보통신망이용촉진및정보보호에관한법률 및 개인정보보호법에서 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
                            <li>6. 이용자는 언제든지 "XR북"이 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수 있으며 "XR북"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "XR북"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</li>
                            <li>7. "XR북"은 개인정보 보호를 위하여 이용자의 개인정보를 처리하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</li>
                            <li>8. "XR북" 또는 그로부터 개인 정보를 제공받는 제3자는 개인정보의 수집 목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
                            <li>9. "XR북"은 개인정보의 수집•이용•제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집•이용•제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집•이용•제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag19">
                    <h5>제 19 조 (XR북의 의무)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는데 최선을 다하여야 합니다.</li>
                            <li>2. "XR북"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.</li>
                            <li>3. "XR북"이 상품이나 용역에 대하여 "표시,광고의공정화에관한법률" 제3조 소정의 부당한 표시, 광고 행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.</li>
                            <li>4. "XR북"은 이용자가 원하지 않는 영리 목적의 광고성 전자 우편을 발송하지 않습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag20">
                    <h5>제 20 조 (회원의 ID 및 비밀번호에 대한 의무)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 제 18조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.</li>
                            <li>2. 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.</li>
                            <li>3. 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "XR북"에게 통보하고 "XR북"의 안내가 있는 경우에는 그에 따라야 합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag21">
                    <h5>제 21 조 (이용자의 의무)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>이용자는 다음 행위를 하여서는 안됩니다.
                                <ul className="detailBx">
                                    <li>(1) 개인정보를 허위로 등록, 신청, 변경하는 행위</li>
                                    <li>(2) 타인의 명의나 카드정보 등을 도용하여 물품을 구입하거나, 물품구입 등을 가장하여 "XR북"의 서비스를 이용하는 행위.</li>
                                    <li>(3) "XR북"이 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법(컴퓨터 프로그램 포함)으로 서비스를 이용하거나 시스템에 접속하는 행위</li>
                                    <li>(4) "XR북" 기타 제3자의 지적재산권을 침해하는 행위</li>
                                    <li>(5) "XR북" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                                    <li>(6) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "XR북"에 공개 또는 게시하는 행위</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag22">
                    <h5>제 22 조 (저작권의 귀속 및 이용 제한)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"이 작성한 저작물에 대한 저작권 기타 지적 재산권은 "XR북"에 귀속합니다.</li>
                            <li>2. 이용자는 "XR북"을 이용함으로써 얻은 정보 중 "XR북"에게 지적 재산권이 귀속된 정보를 "XR북"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</li>
                            <li>3. "XR북"은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag23">
                    <h5>제 23 조 (이용자의 게시물)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>이용자가 “XR북”에 등록 또는 게시한 게시물에 대한 모든 책임은 이용자에게 있으며, “XR북”은 이용자의 게시물이 다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 “XR북”은 어떠한 책임도 지지 않습니다.
                                <ul className="detailBx">
                                    <li>(1) 공공질서와 미풍양속을 저해하는 내용</li>
                                    <li>(2) 폭력적이거나 저속하고 음란한 내용</li>
                                    <li>(3) 불법 복제, 해킹, 기타 현행법을 위반하거나 저촉할 우려가 있는 내용</li>
                                    <li>(4) 특정 개인이나 단체를 모욕하거나 명예를 훼손하고 피해를 줄 의도를 담고 있는 내용</li>
                                    <li>(5) 개인 신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용</li>
                                    <li>(6) 타인의 지적 재산권, 초상권 등 권리를 침해하는 내용</li>
                                    <li>(7) 광고, 홍보, 판촉 등 영리를 목적으로 한 상업적 내용</li>
                                    <li>(8) 사적인 정치적 판단이나 종교적 견해로 이용자 간 위화감을 조장하는 내용</li>
                                    <li>(9) 서비스 각 영역이 규정하고 있는 운영 원칙에 어긋나거나 부합하지 않는 내용</li>
                                    <li>(10) 이해 당사자의 삭제 요청이 있거나 회사가 피소, 고발될 수 있는 근거를 제공하는 경우</li>
                                    <li>(11) 동일한 내용을 반복 게시하는 등 다른 이용자의 서비스 이용에 지장을 초래하는 경우</li>
                                    <li>(12) 정당한 사유 없이 회사의 영업을 방해하는 내용의 게시물</li>
                                    <li>(13) 악성코드나 스파이웨어 등이 실행되어 다른 이용자, 회사, 판매자 기타 제3자의 시스템 성능 저하, 개인정보 유출 등의 피해를 줄 수 있는 악의적인 코드를 담고 있는 게시물</li>
                                    <li>(14) 기타 관계법령에 위배된다고 판단되는 내용</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag24">
                    <h5>제 24 조 (게시물의 관리)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 이용자의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보 보호법』 및 『저작권법』등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라 조치를 취하여야 합니다.</li>
                            <li>2. 회사는 전항에 따른 권리자의 요청이 있는 경우 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다. 이때, 임시조치 한 사실은 이용자가 알 수 있도록 하고 기간은 30일 이내로 합니다.</li>
                            <li>3. 이 조에 따른 세부절차는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보 보호법』 및 『저작권법』등 관련 법령이 규정한 범위 내에서 회사가 정한 게시중단요청서비스에 따릅니다.</li>
                            <li>▶ 게시중단요청서비스 : 더에이치알더 주식회사 02.3288.9009~9010</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag25">
                    <h4>제 2 장 이러닝서비스 이용계약의 청약철회, 해제/해지</h4>
                    <h5>제 25 조 (이용자의 청약철회와 그 효과)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 이용자는 회사로부터 이용신청확인 통지를 받은 날로부터 7일 이내에는 이러닝서비스 이용계약의 청약을 철회할 수 있습니다.</li>
                            <li>2. 이용자의 청약철회는 이용자가 전화/전자우편 등으로 회사에 그 의사를 표시한 때에 효력이 발생합니다. 회사는 이용자의 청약철회의 의사표시를 수신한 후 지체 없이 이러한 사실을 이용자에게 회신합니다.</li>
                            <li>3. 회사는 이용자가 청약철회의 의사표시를 한 날로부터 3 영업일 이내에 이용자의 이용금액을 공제하고 나머지 이용대금을 환급합니다.</li>
                            <li>4. 청약을 철회할 때 교재 등 반환이 필요한 경우에는 해당 비용은 이용자가 이를 부담합니다.</li>
                            <li>5. 회사는 청약철회를 이유로 "이용자"에게 위약금 또는 손해배상을 청구하지 않습니다.</li>
                            <li>6. 이용자가 수강확인증을 발급받은 후 청약철회를 원할 시 회사가 제출기관에 대한 정보를 확인한 후 이용자는 회사로부터 이용대금을 환급받으실 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag26">
                    <h5>제 26 조 (이용자의 계약해제/해지와 그 효과])</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 이용자는 다음 각 호의 사유가 있을 때에는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내 또는 해당 이러닝서비스를 공급받은 날로부터 3월 이내에 이러닝서비스 이용계약을 해제할 수 있으며 회사는 이용대금 전액을 환급합니다.
                                <ul className="detailBx">
                                    <li>(1) 이러닝서비스가 제공되지 않거나, 제공된 이러닝서비스가 표시/광고 등과 현저한 차이가 있는 경우</li>
                                    <li>(2) 회사가 이용자의 동의 없이 무료 이러닝서비스를 유료 서비스로 전환한 경우</li>
                                    <li>(3) 회사가 제시한 최소한의 기술사양을 충족하였음에도 불구하고 이러닝서비스 이용이 불가능한 경우</li>
                                    <li>(4) 기타 이러닝서비스의 결함으로 인하여 정상적인 이용이 불가능한 경우</li>
                                </ul>
                            </li>
                            <li>2. 이용자는 이러닝서비스를 이용하는 계약기간 중 언제든지 계약을 해지할 수 있으며 회사는 수강기간에 따라 아래와 같이 학습비를 환급합니다.</li>
                            <li>3. 회사는 이용자로부터 계약해제/해지의 의사표시를 받은 날로부터 3 영업일 이내에 이용대금을 환급합니다.</li>
                            <li>4. 과오금의 환불
                                <ul className="detailBx">
                                    <li>(1) 회사는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 결제대금 전액을 환불하여야 합니다. 다만 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지하여야 합니다.</li>
                                    <li>(2) 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용, 수수료 등에 관계없이 과오금 전액을 환불하여야 합니다. 다만 이용자의 책임 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 합니다.</li>
                                    <li>(3) 이용자가 주장하는 과오금에 대해 환불을 거부하고자 하는 회사는 정당하게 이용대금이 부과되었음을 입증하여야 합니다.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag27">
                    <h5>제 27 조 (회사의 계약해제/해지 및 그 효과)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 회사는 이용자가 책임 있는 사유로 이 약관에서 정한 의무를 위반함으로써 이러닝서비스 이용계약관계를 유지할 수 없을 정도로 회사와의 신뢰관계를 저버린 경우에는 계약을 해제/해지할 수 있습니다.</li>
                            <li>2. 회사는 이용자에게 계약해제/해지의 의사표시를 한 날로부터 7 영업일 이내에 제25조의 반환기준에 따라 이용대금을 환급합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag28">
                    <h5>제 28 조 이용자 이용금액 산정)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 본 약관에서 이용자의 이용금액을 공제하는 경우 이용자의 이용금액은 아래와 같이 산정합니다.
                                <ul className="detailBx">
                                    <li>(1) '학습기간'을 기준으로 이용대금이 산정되는 이러닝서비스의 경우 : [1일 이용대금 × 기 이용일수]</li>
                                    <li>(2) '학습회차'를 기준으로 이용대금이 산정되는 이러닝서비스 및 '개별 구매 콘텐츠'의 경우: [회차당 이용대금 × 기 이용회차] 다만, "이러닝콘텐츠"를 열거나, 다운로드 받는 등의 행위는 해당 강좌를 이용한 것으로 간주하며, ‘학습회차’에서 ‘1회차’는 ‘1강의’를 의미합니다.</li>
                                </ul>
                            </li>
                            <li>2. 기타 요금제의 경우에는 요금제별 회사의 정책에 따라 환불합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag29">
                    <h5>제 29 조 (환급수단 및 환급금에 대한 지연이자 등)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. 회사가 이용자에게 이용대금 등을 환급하는 경우에는 원칙적으로 이용자가 대금을 결제한 때와 동일한 수단으로 환급합니다. 동일한 수단으로 환급이 불가능할 경우에는 그 사유와 다른 환급수단을 사전에 고지합니다.</li>
                            <li>2. 회사가 이용자에게 환급금 지급을 지연한 경우에는 그 지연기간에 대하여 연 100분의 20을 곱하여 산정한 지연이자를 지급합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag30">
                    <h5>제 30 조 (시범학습기간)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. “회사”는 “이용자”가 계약체결 전에 해당 “이러닝서비스”가 “이용자”의 학습목표와 수준에 적절한지 여부를 판단할 수 있도록 실질적으로 학습이 가능한 1회 이상의 시범학습기간 내지 기회를 제공합니다.</li>
                            <li>2. 다음 각 호의 경우 시범학습기간을 부여한 것으로 보지 않습니다.
                                <ul className="detailBx">
                                    <li>(1) “회사” 및 강좌에 관한 광고만을 하는 경우</li>
                                    <li>(2) 강사의 소개 및 강의 진도, 진행계획만을 안내하는 경우</li>
                                    <li>(3) 시험대비 강좌의 경우 시험유형·경향 및 공부 방법만을 소개하는 경우</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>





                <div className="ag ag31">
                    <h4>제 3 장 기타사항</h4>
                    <h5>제 31 조 (규약 위반시 책임)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. “XR북”과 이용자가 이 규약을 위반함으로써 발생한 모든 책임은 위반한 자가 부담하며, 이로 인해 상대방에게 손해를 입힌 경우에는 손해를 입힌 자가 배상하여야 합니다.</li>
                            <li>2. 이 규약에 따른 거래에 관한 소송은 이용자의 소재지에 있는 관할 법원과 당사가 위치한 관할 법원으로 합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag32">
                    <h5>제 32 조 (면책)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. “XR북”은 천재지변, 불가항력 기타 회사의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.</li>
                            <li>2. “XR북”은 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.</li>
                            <li>3. “XR북”은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를 이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.</li>
                            <li>4. 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당 이용자가 책임을 부담하며, “XR북”은 내용의 부정확 또는 허위로 인해 이용자 또는 제3자에게 발생한 손해에 대하여는 아무런 책임을 부담하지 않습니다.</li>
                            <li>5. “XR북”은 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한 손해에 대하여는 아무런 책임을 부담하지 않습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag33">
                    <h5>제 33 조 (분쟁 해결)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상 처리하기 위하여 피해 보상 처리 기구인 고객만족센터를 설치 운영합니다.</li>
                            <li>2. "XR북"은 이용자로부터 제출되는 불만 사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보합니다.</li>
                            <li>3. "XR북"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해 구제 신청이 있는 경우에는 공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag34">
                    <h5>제 34 조 (준거법 및 관할법원)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1. "XR북"과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방 법원의 전속 관할로 합니다. 다만 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사 소송법상의 관할 법원에 제기합니다.</li>
                            <li>2. 본 약관과 관련된 사항에 관하여는 대한민국법을 준거법으로 합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag35">
                    <h5>부칙 (약관의 효력)</h5>
                    <div className="uaBox">
                        <ul>
                            <li>이 약관은 2020년 9월 1일부터 시행합니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
}
export default TermsOfService;