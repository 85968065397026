import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import * as ROUTES from '../../../../constants/routes';
import './ClassroomLearning.css'
import './ClassroomLearningRes.css'
import $ from "jquery";
import MainSwiper from '../MainSwiper.jsx'

class ClassroomLearning extends Component {
    state={
        index: 0,
        bgImgUrl:'',
        slideList:[]
    }
    // componentDidMount(){
    //     this.getclassInfo()
    //     this.imageSlide()
    // }
    getclassInfo = () => {
        this.props.firebase.mainSection('class').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            console.log(err)
            // window.location.reload()
        })
    }
    setIncrement = () => {
        this.props.firebase.tlpStatistics().update({
            TotalTLPbrochure : this.props.firebase.fieldValue.increment(1)
        })
        .catch(err=> {
            console.log(err)
        })
    }
 
    imageSlide = () =>{
        setInterval(function(){
        	var first_child = $(".clContBox ul li").eq(0);
        	$(".clContBox ul").animate({"margin-left" :"-100%"}, 2000, function(){
        		$(".clContBox ul").append(first_child).css("margin-left", "0");
        	});
        }, 6000);
    }


    render(){
    const { theLittlePrinceByTheHRDer } = this.props
    // console.log(this.props)
    const {slideList} = this.state
    return (
        <React.Fragment>
            <div className="sectOutFrame clOutFrame">
                {/* <MainSwiper slideList={slideList} xrbookByTheHRDer={xrbookByTheHRDer} idName='classlearningSlide' />          */}
                <div className="clInFrame cl1stInFrame">
                    <div className="TitleBox">
                        <ul>
                            <li className="contTitleText"><span>어린 왕자,</span><span>나를 만나다</span></li>
                            <li className="contentText">
                                <span>XR BOOK 『어린 왕자, 나를 만나다』 Learning Program은, 
                                    어린 왕자를 XR을 통해 확장하여 체험하고 어린 왕자와의 
                                    대화로 학습자에게 ‘성찰’과 ‘회복’의 시간을 선사해 줍니다.
                                     이 과정에서 느낀 학습자의 생각과 감정을 기록해 세상 하나 밖에 없는 
                                     나만의 책을 만들어 가는 Healing 프로그램 입니다.</span>
                                <span>속도와 경쟁에 익숙한 우리에게 성찰은 그 어느 때보다도 성장을 위해 반드시 필요한 요소입니다. 성찰은 회복을 가져다 주는 원동력이며, 회복은 개인과 조직의 지속적인 성장 에너지 입니다.</span> 
                                <span>XR BOOK 『어린 왕자, 나를 만나다』는</span>
                                <span>온전히 나를 위한 Healing의 시간입니다. </span>
                            </li>
                            <li className="buttonText"><a href={theLittlePrinceByTheHRDer} target='_blank' onClick={()=>this.setIncrement()} download="어린왕자소개서_뉴클">어린 왕자 소개서 다운로드</a></li>
                        </ul>
                    </div>
                    <div className="contBox">
                        <div className="contImage">
                            <span></span>
                        </div>
                        <div className="contText">
                            <ul>
                                <li>
                                    <ul>
                                        <li>2D Animation</li>
                                        <li>Prologue. 어린 왕자</li>
                                        <li>Epilogue. 나의 어린 왕자</li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li>
                                            <span>VR Activity</span>
                                            <span>Reflection Writing</span>
                                            <span>3D Animation <span>360</span></span>
                                        </li>
                                        <li>Chapter1. 어린 왕자를 만나다</li>
                                        <li>Chapter2. 소행성 사람들을 만나다</li>
                                        <li>Chapter3. 친구를 만나다</li>
                                        <li>Chapter4. 나를 만나다</li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li>AR Activity</li>
                                        <li>WITH. 어린 왕자</li>
                                        <li>WITH. 소행성 사람들</li>
                                        <li>WITH. 지구별 친구</li>
                                        <li>SHARE. 나의 어린 왕자</li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li>나를 위한 힐링의 시간</li>
                                        <li>XR BOOK을 통한 새로운 학습 경험</li>
                                        <li>나의 어린 왕자 책 쓰기</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );}
};

export default withFirebase(ClassroomLearning);


